import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  contactDetailsList,
  submitContactDetails,
} from "../service/ContactMaster/ContactMaster";
import Toast from "../util/Toast";
const Contact = () => {
  const [contactData, setContactData] = useState();
  const [getInTouchDetails, setGetInTouchDetails] = useState({
    firstName: "",
    lastName: "",
    title: "",
    company: "",
    contact: "",
    email: "",
    subject: "",
    message: "",
    others: "",
  });
  const [errors, setErrors] = useState({});

  const URL = process.env.REACT_APP_API_URL;
  const baseURL = URL.replace("/v1", "");
  const imageURL = `${baseURL}/images/`;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGetInTouchDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Reset the error when the user starts typing
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const {
      firstName,
      lastName,
      title,
      company,
      contact,
      email,
      subject,
      message,
    } = getInTouchDetails;

    // Validate required fields
    if (!firstName.trim()) newErrors.firstName = "First Name is required";
    if (!lastName.trim()) newErrors.lastName = "Last Name is required";
    if (!title.trim()) newErrors.title = "Title is required";
    if (!company.trim()) newErrors.company = "Company is required";
    if (!email.trim()) newErrors.email = "Email address is required";
    if (!subject) newErrors.subject = "Please select the subject";
    if (!message) newErrors.message = "Message is required";

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email))
      newErrors.email = "Enter a valid email";

    // Validate phone number format (e.g., 10 digits)
    const phoneRegex = /^\d{10}$/;
    if (contact && !phoneRegex.test(contact))
      newErrors.contact = "Enter a valid 10-digit contact number";

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // useEffect(() => {
  //   // Dynamically create and load the script
  //   const script = document.createElement('script');
  //   script.src = "https://formfacade.com/include/111121632664178442567/form/1FAIpQLScuf34F2a1Hlj29cTkczRJG5x8kOzUN43C-0ThbwOHwvO1zQA/classic.js?div=ff-compose";
  //   script.async = true;
  //   script.defer = true;

  //   // Append the script to the body of the page
  //   document.body.appendChild(script);

  //   // Cleanup the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  

  const getContactDetails = () => {
    contactDetailsList()
      .then((res) => {
        const data = res?.data?.data[0];
        setContactData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  const handleSubmit = () => {
    if (!validateForm()) {
      // If validation fails, do not proceed with the form submission
      return;
    }

    try {
      const result = submitContactDetails(getInTouchDetails);
      Toast(result).then((res) => {
        if (res?.data?.status === true) {
          setGetInTouchDetails({
            firstName: "",
            lastName: "",
            title: "",
            company: "",
            contact: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
          console.error("Failed to save data");
        }
      });
    } catch (error) {
      // Handle any errors
      console.error("Error:", error);
    }
  };

  return (
    <div className="main">
      <div
        className="site-breadcrumb"
        style={{
          backgroundImage: contactData?.mainImage
            ? `url(${imageURL}${contactData?.mainImage})`
            : "", // Fallback image if mainImage is not available
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Contact</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="active">Contact</li>
          </ul>
        </div>
      </div>

      <div className="contact-area py-120">
        <div className="container">
          <div className="contact-content">
            <div className="row">
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fal fa-map-location-dot"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Office Address</h5>
                    <p>{contactData?.address}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fal fa-phone-volume"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Call Us</h5>
                    <p>{contactData?.contactus}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fal fa-envelopes"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Email Us</h5>
                    <p>{contactData?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-wrapper">
            <div className="row g-4">
              <div className="col-lg-5">
                <div className="contact-img">
                  <img alt="" src={`${imageURL}${contactData?.sideImage}`} />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Ready to host your own event?</h2>
                  </div>

                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScuf34F2a1Hlj29cTkczRJG5x8kOzUN43C-0ThbwOHwvO1zQA/viewform?embedded=true" width="640" height="1430" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96708.34194156103!2d-74.03927096447748!3d40.759040329405195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a01c8df6fb3cb8!2sSolomon%20R.%20Guggenheim%20Museum!5e0!3m2!1sen!2sbd!4v1619410634508!5m2!1sen!2s"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div> */}
    </div>
  );
};

export default Contact;
