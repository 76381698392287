import React, { useEffect, useState } from "react";
import shape01 from "../../assets/img/shape/01.png";
import logo from "../../assets/img/logo/logo-white.png";
import { Link } from "react-router-dom";
import { contactDetailsList } from "../../service/ContactMaster/ContactMaster";

const Footer = () => {
  const [contactData, setContactData] = useState();

  const getContactDetails = () => {
    contactDetailsList()
      .then((res) => {
        const data = res?.data?.data[0];
        setContactData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getContactDetails();
  }, []);
  return (
    <footer className="footer-area">
      <div className="footer-shape">
        <img src={shape01} alt="images" />
      </div>
      <div className="footer-widget">
        <div className="container">
          <div className="row footer-widget-wrapper pt-100 pb-70">
            <div className="col-md-6 col-lg-5">
              <div className="footer-widget-box about-us">
                <Link to="#" className="footer-logo">
                  <img src={logo} alt="images" />
                </Link>
                {/* <p className="mb-3">
                  We are many variations of passages available majority have
                  suffered in some injected content of a page when looking at
                  its layout humour words believable.
                </p> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="footer-widget-box list">
                <h4 className="footer-widget-title">Quick Links</h4>
                <ul className="footer-list">
                  <li>
                    <Link to="/">
                      <i className="fas fa-caret-right" /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <i className="fas fa-caret-right" /> About
                    </Link>
                  </li>
                  <li>
                    <Link to="/events">
                      <i className="fas fa-caret-right" /> Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <i className="fas fa-caret-right" /> Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="footer-widget-box list">
                <h4 className="footer-widget-title">Our Social</h4>
                <ul className="footer-list social">
                  <li>
                    <Link target="_blank" to="https://www.linkedin.com/company/suitelink/">
                      <i className="fab fa-linkedin-in" /> Linkedin
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.instagram.com/suite_link/">
                      <i className="fab fa-instagram" /> Instagram
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-widget-box list">
                <h4 className="footer-widget-title">Get In Touch</h4>
                <ul className="footer-contact">
                  <li>
                    <Link to="tel:+21236547898">
                      <i className="far fa-phone" />
                      {contactData?.contactus}
                    </Link>
                  </li>
                  <li>
                    <i className="far fa-map-marker-alt" />
                    {contactData?.address}
                  </li>
                  <li>
                    <Link to={`mailto:${contactData?.email}`}>
                      <i className="far fa-envelope" />
                      {contactData?.email}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6">
              <p className="copyright-text">
                © Copyright <span id="date" />
                2024 <Link to="#"> Suitelink </Link> All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6" style={{display:"flex",justifyContent:"end"}}>
            
           </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
